<template>
    <Calendar 
        :related_object="task.id"
        :addEventCheck="addEventCheck" />
</template>

<script>
import Calendar from '@apps/Calendar'
export default {
    props: {
        task: {
            type: Object,
            required: true
        },
        isOperator: {
            type: Boolean,
            default: false
        },
        isAuthor: {
            type: Boolean,
            required: true
        },
        myTask: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        addEventCheck() {
            return this.myTask || this.isAuthor || this.isOperator ? true : false
        }
    },
    components: {
        Calendar
    }
}
</script>